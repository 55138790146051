html {
  height: 100%;
  background-color: var(--bg);
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
}

* {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.2);
}

#root {
  height: 100%;
}

.App {
  text-align: left;
  background-color: var(--bg);
  color: var(--text);
  height: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

h3 {
  margin-bottom: 2rem;
}