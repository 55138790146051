.tableOuter {
  width: 100%;
  height: 100vh;
  border-spacing: 0rem;
  overflow: hidden;
}

.leftMenu {
  width: 15rem;
  height: 100vh;
  padding: 0;
}

.mainContent {
  width: calc(100% - 3rem);
  height: calc(100vh - 2rem);
  padding: 0;
  display: block;
  margin-left: 3rem;
  padding: 1rem 0rem;
  overflow-y: auto;
}

.mobileHeader {
  background-color: var(--bg);
  color: var(--text);
  padding: 0.5rem 1rem;
}

.mobileHeaderLeft {
  display: flex;
  align-items: center;
}

.mobileContentOuter {
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100% - 4rem);
}

.mobileContent {
  padding: 0rem 1.5rem;
  transform: scale(0.85);
  width: 100%;
  height: 100%;
}

.mobileNavMenuHolder {
  padding: 1.5rem 1.5rem 1.5rem 0rem;
  width: 15rem;
  max-width: 100vw;
  height: 100%;
  background-color: var(--bg);
}
